$(function(){

  // hide other foodtrucks cards and show details of choosen one
  $('.foodtruck').on('click', function(e){
    if (!$(this).hasClass('opened')) {
      $(this).siblings('.foodtruck').addClass('hidden');
      $(this).addClass('opened');
    }
  });

  // hide details of choosen one and show all foodtruck cards
  $('.foodtruck-close').on('click', function(e){
    e.stopPropagation();
    $('.foodtruck').removeClass('hidden opened');
  });
});