import "../channels"

import '../old/cards';
import '../old/foodtruck_orders';
import '../old/init_set_menu_modal';
import '../old/navbar';
import '../old/rating-hints';

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import Rails from '@rails/ujs'
Rails.start()

