$(function(){
  $('.foodtruck-orders').on('click', '.order-open-modal', function(e){
    e.preventDefault();
    $order = $(this).closest('.foodtruck-order');

    $('.foodtruck-orders .order-modal').fadeOut(200);

    if ($order.hasClass('order-canceled')) {
      $order.find('.order-modal-cancel').fadeIn(200);
    } else if ($order.hasClass('order-ready')) {
      $order.find('.order-modal-deliver').fadeIn(200);
    } else if ($order.hasClass('order-pending')) {
      $order.find('.order-modal-in-progress').fadeIn(200);
    } else {
      $order.find('.order-modal-ready').fadeIn(200);
    }
  });

  $('.foodtruck-orders').on('click', '.btn-in-progress-ack', function(){
    $(this).closest('.foodtruck-order').slideUp();
    $('.news-order-count').html(parseInt($('.news-order-count').html()) - 1)
    $('.in-progress-order-count').html(parseInt($('.in-progress-order-count').html()) + 1)
  });

  $('.foodtruck-orders').on('click', '.btn-canceled-ack', function(){
    $(this).closest('.foodtruck-order').slideUp();
    $('.past-order-count').html(parseInt($('.past-order-count').html()) + 1)
  });

  $('.foodtruck-orders').on('click', '.btn-delivered-ack', function () {
    $('.past-order-count').html(parseInt($('.past-order-count').html()) + 1)
    $('.in-progress-order-count').html(parseInt($('.in-progress-order-count').html()) - 1)
  });

  $('.foodtruck-orders').on('click', '.order-modal-backdrop', function(){
    $(this).closest('.order-modal').fadeOut(200);
  });
});