// TODO: Replace jQuery

import consumer from "./consumer"

consumer.subscriptions.create("FoodtruckChannel", {
  status: "?",
  connected: function (data) {
    if (this.status != "disconnected") {
      this.status = "connected";
      this.cleanNotifs();
      $('#cable-status-connected').addClass("active");
      console.log("[CONNECTED]");
    }
  },
  disconnected: function (data) {
    this.status = "disconnected";
    this.cleanNotifs();
    $('#cable-status-reconnecting').addClass("active");
    var that = this;
    setTimeout(function () {
      if (that.status != "connected") {
        that.cleanNotifs();
        $('#cable-status-disconnected').addClass("active");
      }
    }, 3000);
    console.log("[DISCONNECTED]");
  },
  received: function (data) {
    var $newOrder, $nextOrder;
    switch (data.action) {
      case "order_cancel":
        console.log("[ORDER CANCELED]");
        $('#order-' + data.order_id).find('.canceled-tag').removeClass('hidden');
        return $('#order-' + data.order_id).addClass("order-canceled");
        break;
      case "order_new":
        console.log("[NEW ORDER]");
        $('#foodtruck-ding').trigger('play');
        $('.news-order-count').html(parseInt($('.news-order-count').html()) + 1)
        if (document.querySelector('.orders-news') == null) {
          break
        }
        $nextOrder = $('.foodtruck-order').filter(function () {
          return parseInt($(this).data("timecode")) > parseInt(data.timecode);
        }).first();
        $newOrder = $(data.html).hide();
        if ($nextOrder.length) {
          $($newOrder).insertBefore($nextOrder);
        } else {
          $('.foodtruck-orders').append($newOrder);
        }
        $newOrder.slideDown(200);
        var $newTag = $newOrder.find('.new-tag');
        $newTag.removeClass('fade');
        setTimeout(function () {
          $newTag.addClass('fade');
        }, 10);
        break;
      case "order_ready":
        console.log("[ORDER IS READY (automatically)]");
        if (data.errors) {
          alert("Erreur, prévenez l'équipe technique.")
        } else {
          $('#order-' + data.order_id).addClass('order-ready');
          $('#order-' + data.order_id).find('.ready-tag').removeClass('hidden');
          $('#order-' + data.order_id).find('.new-tag').addClass('hidden');
        }
        break;
    }
  },
  cleanNotifs: function () {
    $('.cable-status').removeClass('active');
  }
});